<template>
  <span>
    <template v-if="havePoll">
      <div v-for="(part, key) in experienceParts" :key="key">
        <v-card rounded="lg" v-if="part.type == 'poll'">
          <div class="title px-4 pt-4 pb-2" style="word-wrap: break-word;">
            {{ part.title }}
          </div>
          <v-card-text>
            <div>
              {{ part.description }}
            </div>
            <ul>
              <li v-for="(choice, choiceKey) in part.choices" :key="choiceKey" class="py-1">
                <span class="black--text">
                  {{ choice.text }}
                </span>
                <v-progress-linear
                  :value="getChoicePercentage(choice.key, part.poll_answers)"
                  color="cyan"
                  height="25"
                  rounded
                >
                  <template v-slot:default="{ value }">
                    <strong>{{ Math.ceil(value) }}%</strong>
                  </template>
                </v-progress-linear>
              </li>
            </ul>
          </v-card-text>
        </v-card>
      </div>
    </template>
    <template v-else>
      <v-card rounded="lg">
        <v-card-text class="font-weight-bold text-center">
          No poll for this experience.
        </v-card-text>
      </v-card>
    </template>
  </span>
</template>

<script>
export default {
  props: {
    experienceParts: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    havePoll: function() {
      return this.experienceParts.some(item => item.type == 'poll');
    }
  },
  methods: {
    getChoicePercentage(choiceKey, pollAnswers) {
      let filteredPollAnswer = pollAnswers.filter(item => item.key == choiceKey);

      // return {
      //   count: filteredPollAnswer.length || 0,
      //   total: pollAnswers.length || 0,
      //   percentage: (filteredPollAnswer.length/pollAnswers.length) * 100
      // };

      return (filteredPollAnswer.length/pollAnswers.length) * 100;
    }
  }
}
</script>